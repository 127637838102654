var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoices-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 12 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: _vm.loading },
                },
                [
                  _vm.invoices.length
                    ? _c(
                        "div",
                        {
                          staticClass: "key-value",
                          class: { selected: _vm.filter === null },
                          on: {
                            click: function ($event) {
                              _vm.filter = null
                            },
                          },
                        },
                        [
                          _c("label", [
                            _vm._v("Total invoiced "),
                            _c("span", [_vm._v("(ex. GST)")]),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("currency")(_vm.total_invoiced))
                            ),
                          ]),
                        ]
                      )
                    : _c("el-alert", {
                        attrs: {
                          description: "No invoices available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: _vm.loading },
                },
                [
                  _vm.invoices.length
                    ? _c(
                        "div",
                        {
                          staticClass: "key-value",
                          class: { selected: _vm.filter === "paid" },
                          on: {
                            click: function ($event) {
                              _vm.filter = "paid"
                            },
                          },
                        },
                        [
                          _c("label", [
                            _vm._v("Total paid "),
                            _c("span", [_vm._v("(ex. GST)")]),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(_vm.total_paid))),
                          ]),
                        ]
                      )
                    : _c("el-alert", {
                        attrs: {
                          description: "No invoices available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, sm: 24 } },
            [
              _c(
                "widget-card",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { loading: _vm.loading },
                },
                [
                  _vm.invoices.length
                    ? _c(
                        "div",
                        {
                          staticClass: "key-value",
                          class: { selected: _vm.filter === "due" },
                          on: {
                            click: function ($event) {
                              _vm.filter = "due"
                            },
                          },
                        },
                        [
                          _c("label", [
                            _vm._v("Total due "),
                            _c("span", [_vm._v("(inc. GST)")]),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("currency")(_vm.total_due))),
                          ]),
                        ]
                      )
                    : _c("el-alert", {
                        attrs: {
                          description: "No invoices available.",
                          closable: false,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.sorted_invoices.length
        ? _c(
            "ul",
            { staticClass: "invoices-list" },
            _vm._l(_vm.sorted_invoices, function (invoice) {
              return _c("invoice-block", {
                key: invoice.id,
                attrs: { invoice: invoice },
              })
            }),
            1
          )
        : _vm.loading
        ? _c("el-alert", {
            attrs: { description: "Loading invoices.", closable: false },
          })
        : _c("el-alert", {
            attrs: {
              description: "No invoices for this filter.",
              closable: false,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }